<template scoped>
  <div>
    <el-form-item
      :rules="rules.flow"
      label="Flow"
      prop="execute.flow.id"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        v-model="logic.execute.flow.id"
        :style="{ width: '100%' }"
        filterable
        remote
        reserve-keyword
        placeholder="Flow"
        :remote-method="queryFlow"
        :loading="remote.flow.loading"
      >
        <el-option
          v-for="item in remote.flow.suggestion"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script scoped>
export default {
  props: {
    logic: Object,
    endpoint: String,
    productId: String,
  },
  methods: {
    validator_flow: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the flow should not be empty"));
      } else {
        callback();
      }
    },
    queryFlow: function (query) {
      var $this = this;
      this.remote.flow.loading = true;
      fetch(
        `${this.endpoint}/api/Backend/Flow?Name=${query}&ProductId=${this.productId}&Page=1&PageSize=10`
      )
        .then(function (request) {
          return request.json();
        })
        .then(function (response) {
          $this.remote.flow.suggestion = response.result.result;
          $this.remote.flow.loading = false;
        });
    },
  },
  data: function () {
    return {
      rules: {
        flow: [{ validator: this.validator_flow, trigger: "blur" }],
      },
      remote: {
        flow: {
          loading: false,
          suggestion: [],
        },
      },
    };
  },
};
</script>